<template>
    <div>
        <mobile-header></mobile-header>
        <div class="home-container" v-show="showResult == 0">
            <!-- banner -->
            <img class="img-banner" id="diy"  src="@A/images/mobile/mobile_banner.png" alt="">

            <!-- 制作图片-->

            <div class="make-photo column-center" >
                <div class="make-photo-top">
                    <!-- 还没制作完成 -->
                    <div v-show="!isCreate">
                        <van-uploader v-show="!showUploadPicture" result-type="file" v-model="fileList" :before-read="beforeRead" :after-read="afterRead" accept="image/png, image/jpeg, image/jpg" :max-count="1">
                            <div>
                                <div class="make-photo-click-container row-center">
                                    <img class="make-photo-click " src="@A/images/mobile/mobile_click.png" alt="">
                                </div>
                                <div class="txt-click-upload nr mt5">点击上传一张图片</div>
                            </div>
                        </van-uploader>
                        <div class="photo-preview-container" v-show="showUploadPicture">
                            <div class="photo-preview-area row-center" @click="previewImage(uploadImage)">
                                <img class="preview-image" ref="previewImg" :src="uploadImage" v-loading.fullscreen.lock="imgUpload" >
                            </div>
                            <div class="re-upload-btn white row-center nr mt5" @click="reUpload">点击重新上传图片</div>
                        </div>
                    </div>
                    <!-- 制作完成 -->
                    <div class="create-box" v-show="isCreate">
                        <div class="create-preview-area row-center">
                            <div class="preview-left row-center" id="picture-container">
                            </div>
                            <div class="preview-right row wrap ml10">
                                <div class="top-container column-between">
                                    <div class="top"></div>
                                    <div class="bottom"></div>
                                </div>
                                <div class="left">
                                    <div class="left-top mb10">
                                        <img :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                    <div class="left-bottom">
                                        <img :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                </div>
                                <div class="right ml10">
                                    <div class="right-top mb10">
                                        <img :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                    <div class="right-bottom">
                                        <img :style="{'background': pictureBackground.length > 0 && (pictureBackground[0].type == 'img' ? 'url(' + pictureBackground[0].icon + ')' : pictureBackground[0].color)}" :src="imageShown" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="primary re-upload-btn row-center nr" @click="reUpload">重新上传</div>
                    </div>
                    <div @click="selectInch()" class="photo-size-container row">
                        <div class="right-title xs">照片尺寸：</div>
                        <div class="select-inch-container row">
                            <div class="xs">{{selectedList && selectedList.length && selectedList[0].values}}</div>
                            <img class="img-select" src="@A/images/mobile/mobile_arrow.png" alt="">
                        </div>
                    </div>
                    <div class="photo-bg-container" style="margin-top: 32px;">
                        <div class="right-title row xs" >照片底色：</div>
                        <div class="color-container row">
                            <div @click="handleColorClick(item, index)" class="color-box-item" :class="{'active': item.selected, 'white-border': item.border}" v-for="(item, index) in colorList" :key="index">
                                <div v-if="item.type == 'color'" :style="{'background-color': item.color}" style="height: 100%"></div>
                                <img v-else style="height: 100%" src="@A/images/color-trans.png"/>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 15px">
                        <div class="row-between">
                            <div class="right-title xs" >一键美颜</div>
                            <van-switch v-model="isBeauty" :disabled="isCreate || btnCreating" size="12px" inactive-color="rgb(229,229,229)" />
                            </div>
                            <van-button @click="$createCrditUnChange" v-if="!isCreate" class="create-btn white row-center lg" :disabled="fileList.length <= 0" :style="{'opacity': fileList && fileList.length > 0 ? '1' : '0.4'}">
                                开始生成证件照
                            </van-button>
                            <van-button @click="downloadClick" v-else class="download-btn white row-center lg">
                                支付19.99元，获取无水印版本证件照
                            </van-button>
                    </div>
                </div>
                <div class="xs">请核对预览图没有问题，然后再下载高清无水印证件照</div>
            </div>


            <!-- AI处理快捷方便、安全隐私 海量证件照在线制作、轻松处理各类证件照-->
            <div class="bottom-container column-center">
                <div class="lg bottom-top-title">AI处理快捷方便、安全隐私</div>
                <!-- 四个图片 -->
                <div class="row girl-container">
                    <img class="girl_photo" src="@A/images/mobile/mobile_girl_one.png" alt="">
                    <img class="girl_photo girl_photo_left" src="@A/images/mobile/mobile_girl_two.png" alt="">
                    <img class="girl_photo girl_photo_left" src="@A/images/mobile/mobile_girl_three.png" alt="">
                    <img class="girl_photo girl_photo_left" src="@A/images/mobile/mobile_girl_four.png" alt="">
                </div>
                <!-- 流程 -->
                <div class="row progress-container">
                    <div class="txt-progress xs">上传图片</div>
                    <img class="img-progress" src="@A/images/mobile/mobile_girl_progress.png" alt="">
                    <div class="column-center">
                        <div class="txt-progress xs">选择尺寸</div>
                        <div class="txt-progress xs">和背景色</div>
                    </div>

                    <img class="img-progress" src="@A/images/mobile/mobile_girl_progress.png" alt="">
                    <div class="column-center">
                        <div class="txt-progress xs">软件智能</div>
                        <div class="txt-progress xs">抠图处理</div>
                    </div>
                    <img class="img-progress" src="@A/images/mobile/mobile_girl_progress.png" alt="">
                    <div class="column-center">
                        <div class="txt-progress xs">下载无水</div>
                        <div class="txt-progress xs">印证件照</div>
                    </div>
                </div>

                <div @click="toTop"  class="girl-click row-center lg">立即制作</div>

                <!-- 海量证件照在线制作、轻松处理各类证件照 -->
                <div class="lg bottom-top-type-title">海量证件照在线制作、轻松处理各类证件照</div>
                <div class="type-container column-center">
                    <div class="row type-one">
                        <div class="column-center">
                            <img class="img-type" src="@A/images/mobile/mobile_type_one.png" alt="">
                            <div class="txt-type xs">常规寸照</div>
                        </div>
                        <div class="column-center type-left">
                            <img class="img-type" src="@A/images/mobile/mobile_type_two.png" alt="">
                            <div class="txt-type xs">职业照</div>
                        </div>
                        <div class="column-center type-left">
                            <img class="img-type" src="@A/images/mobile/mobile_type_three.png" alt="">
                            <div class="txt-type xs">入学照</div>
                        </div>
                    </div>
                    <div class="row type-two">
                        <div class="column-center">
                            <img class="img-type" src="@A/images/mobile/mobile_type_four.png" alt="">
                            <div class="txt-type xs">半身照</div>
                        </div>
                        <div class="column-center type-left">
                            <img class="img-type" src="@A/images/mobile/mobile_type_five.png" alt="">
                            <div class="txt-type xs">考试职称照</div>
                        </div>
                        <div class="column-center type-left">
                            <img class="img-type" src="@A/images/mobile/mobile_type_six.png" alt="">
                            <div class="txt-type xs">其他证件照</div>
                        </div>
                    </div>
                    <div @click="toTop"  class="girl-click row-center lg">立即制作</div>

                    <!-- 底部一条线 -->
                    <div style="width:100%;height:1px;background:#E5E5E5;marginTop:64px"></div>

                    <!-- 公司信息 -->
                    <div class="xs co-info">粤ICP备2024242504号 | 广州皓迅科技有限公司</div>

                </div>
            </div>


            <!-- 选择尺寸弹框 -->
            <van-popup class="popWrap"  position="bottom"  :style="{ height: '50%' }" safe-area-inset-bottom  v-model="showPicker" >
                <van-picker
                    title="选择尺寸"
                    value-key="text"
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    :default-index="0"
                >
                    <div class="primary" slot="confirm">确认</div>
                </van-picker>
            </van-popup>
            <van-popup v-model="btnCreating" round>
                <div class="column-center loading-popup">
                    <van-loading type="spinner" size="42px" vertical :text-size="12" >
                        <div class="mt10">证件照在制作中</div>
                    </van-loading>
                </div>
            </van-popup>
            <van-popup v-model="showPayDialog" closeable round @close="onPayDialogClose">
                <div class="pay-dialog-container">
                    <div class="pay-dialog" v-show="!paySuccess">
                        <div class="price">￥{{price}}</div>
                        <div class="tips xs">支付成功后，获取无水印版证件照</div>
                        <div class="btn-container">
                            <div class="row-center wechat-btn nr" @click="changePayWay(4)">
                                <img src="@A/images/icon_wechat.png" class="mr5" alt="" >
                                微信支付
                            </div>
                            <div class="row-center alipay-btn nr" @click="changePayWay(5)">
                                <img src="@A/images/icon_alipay.png" class="mr5" alt="" >
                                支付宝支付
                            </div>
                        </div>
                    </div>
                    <div class="pay-success column-center" v-show="paySuccess">
                        <img class="pay-status" src="@A/images/mobile/paySuccess.png" />
                        <div class="success-tips xs">已成功支付，即将自动下载</div>
                        <van-button class="down-load-btn" @click="payPictureSuccess()">
                            下载照片
                        </van-button>
                        <div class="muted xs tips">如未自动下载照片，请点击以上按钮</div>
                    </div>
                </div>
            </van-popup>
        </div>
        <div class="picture-container" v-show="showResult == 1">
            <div class="main">
                <img class="exported-image" :src="base64DPI300" alt="">
            </div>
            <div class="control row-center">
                <van-button class="long-btn row-center br60 white">长按保存证件照</van-button>
                <img class="hidden-image" :style="{'heihgt': phoneHeight + 'px'}"  :src="base64DPI300" alt="exported-image">
            </div>
        </div>
    </div>
</template>

<script>
import "lib-flexible/flexible";
import axios from 'axios';
import { ImagePreview } from "vant";
import {getSetup, upload2Image, handleImage, createCrditUnChange, getPriceSetup, pay, searchOrder, operationLog} from '@API/app'
import {apkChannel, apkVersionName, phoneModel, apkVersionCode, apkId, phoneOsVersion, phoneOs} from '@U/versionConfig';
import {changeDpiDataUrl} from "changedpi"
import MobileHeader from '@C/MobileHeader'
import {random_int_str} from "@U/utils"
import md5 from '@U/md5'
import JSMD5 from 'js-md5'
export default {
    data() {
        return {
            sizeList: [
                {
                    title: "一寸",
                    size: "25X35",
                    cutSizeX: 25,
                    cutSizeY: 35,
                    pxSizeX: 295,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: true
                },
                {
                    title: "二寸",
                    size: "35X49",
                    cutSizeX: 35,
                    cutSizeY: 49,
                    pxSizeX: 413,
                    pxSizeY: 579,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "小一寸",
                    size: "22X32",
                    cutSizeX: 22,
                    cutSizeY: 32,
                    pxSizeX: 260,
                    pxSizeY: 378,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "小二寸",
                    size: "35X45",
                    cutSizeX: 35,
                    cutSizeY: 45,
                    pxSizeX: 413,
                    pxSizeY: 531,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "大一寸",
                    size: "33X48",
                    cutSizeX: 33,
                    cutSizeY: 48,
                    pxSizeX: 390,
                    pxSizeY: 413,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "大二寸",
                    size: "35X53",
                    cutSizeX: 35,
                    cutSizeY: 53,
                    pxSizeX: 413,
                    pxSizeY: 626,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "三寸",
                    size: "55X84",
                    cutSizeX: 55,
                    cutSizeY: 84,
                    pxSizeX: 649,
                    pxSizeY: 991,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "四寸",
                    size: "76X102",
                    cutSizeX: 76,
                    cutSizeY: 102,
                    pxSizeX: 898,
                    pxSizeY: 1205,
                    dpi: 300,
                    selected: false
                },
                {
                    title: "五寸",
                    size: "89X127",
                    cutSizeX: 89,
                    cutSizeY: 127,
                    pxSizeX: 1050,
                    pxSizeY: 1499,
                    dpi: 300,
                    selected: false
                },
            ],
            colorList: [
                {
                    title: '红色',
                    type: 'color',
                    color: "red",
                    selected: true,
                    border: false
                },
                {
                    title: '橙色',
                    type: 'color',
                    color: "#F48E22",
                    selected: false,
                    border: false
                },
                {
                    title: '天蓝',
                    type: 'color',
                    color: "#2287F4",
                    selected: false,
                    border: false
                },
                {
                    title: '蓝色',
                    type: 'color',
                    color: "#2254F4",
                    selected: false,
                    border: false
                },
                {
                    title: '白色',
                    type: 'color',
                    color: "#FFFFFF",
                    selected: false,
                    border: true
                },
                // {
                //     title: '透明',
                //     type: 'img',
                //     color: "#00000000",
                //     selected: false,
                //     border: false,
                //     icon: require("@A/images/color-trans.png")
                // }
            ],
            pictureList: [
                {
                    picture: require("@A/images/credit1.png"),
                    desc: "常规寸照"
                },
                {
                    picture: require("@A/images/credit2.png"),
                    desc: "职业照"
                },
                {
                    picture: require("@A/images/credit3.png"),
                    desc: "入学照"
                },
                {
                    picture: require("@A/images/credit4.png"),
                    desc: "半身照"
                },
                {
                    picture: require("@A/images/credit5.png"),
                    desc: "考试职称照"
                },
                {
                    picture: require("@A/images/credit6.png"),
                    desc: "其他证件照"
                },
            ],
            selectedList: [],
            fileList: [],
            pictureBackground: [],
            // 是否美颜
            isBeauty: false,
            value: '一寸(25x35cm)',
            showPicker: false,
            imageShown: '',
            // 是否已经生成证件照了
            isCreate: false,
            // 是否正在生成证件照
            btnCreating: false,
            // 是否展示预览图片
            showUploadPicture: false,
            // 上传后的图片
            uploadImage: '',
            columns: [],
            imgSize: 0,
            // 是否计算中
            isCalc: false,
            // 支付弹窗控制变量
            showPayDialog: false,
            // 支付方式 4微信h5 5支付宝h5
            payWay: 4,
            // 价格
            price: '',
            payStart: false,
            // 判断是否支付成功
            paySuccess: false,
            // 是否下载
            isDownLoad: false,
            // 结果图片
            resultPicture: "",
            // 微信配置
            wxAppId: "",
            wxMchId: "",
            showResult: 0,
            base64DPI300: "",
            phoneHeight: 667,
            imgUpload: false
        }
    },
    components: {
        MobileHeader
    },
    mounted() {
        if(this.timer) {
            clearInterval(this.timer)
        }
        if(localStorage.getItem("phoneId")) {
            this.phoneId = localStorage.getItem("phoneId");
        }
        else {
            this.phoneId = 'PC_phoneID_' + random_int_str(6);
            localStorage.setItem("phoneId", this.phoneId)
        }
        this.pictureBackground = this.colorList.filter((item) => {
            return item.selected == true
        })
        localStorage.setItem("bd_vid", this.$route.query.bd_vid)
        this.bd_vid = this.$route.query.bd_vid || localStorage.getItem("bd_vid");

        if(localStorage.getItem("showPayModal") == 1) {
            this.$store.dispatch("showPayModal");
            // let orderNum = 0;
            // this.wechatTimer = setInterval(() => {
            //     if(orderNum <= 20) {
            //         this.$searchOrder(localStorage.getItem("orderId"))
            //     }
            //     else {
            //         clearInterval(this.wechatTimer)
            //     }
            //     orderNum += 1;
            // }, 1000)
        }

        this.phoneHeight = window.innerHeight
        this.$getSetup();
        this.$getPriceSetup()

        // 判断本地是否存在带水印的照片
        if(localStorage.getItem('imageShown') && localStorage.getItem('imageShown') != '') {
            // 有
            this.imageShown = localStorage.getItem('imageShown');
            this.selectedList[0] = localStorage.getItem("selectedList") && JSON.parse(localStorage.getItem("selectedList"));
            this.pictureBackground[0] = localStorage.getItem("pictureBackground") && JSON.parse(localStorage.getItem("pictureBackground"));
            console.log(this.selectedList, "selectedList");
            this.isBeauty = localStorage.getItem("isBeauty") == 1 ? true : false;
            this.uploadImage = localStorage.getItem("uploadImage");
            this.isCreate = true;
            this.createSaveImage()
        }


    },
    destroyed() {
        if(this.timer) {
            clearInterval(this.timer);
        }
        if(this.wechatTimer) {
            clearInterval(this.wechatTimer)
        }
        if(this.timeoutTimer) {
            console.log('## clear setTImeout ##')
            clearTimeout(this.timeoutTimer);
        }
    },
    methods: {
        // 埋点
        $operationLog(data) {
            operationLog({
                ...data
            }).then(res => {

            })
        },
        // 获取尺寸配置
        $getSetup() {
            getSetup({
                apkChannel,
                apkVersionName,
                phoneModel,
                apkVersionCode,
                apkId,
                phoneOsVersion,
                phoneOs
            }).then(res => {
                if(res.success == "1") {
                    this.sizeList = res.obj.specificationPcRegular;
                    this.sizeList.push(...res.obj.specificationPcMore)
                    this.sizeList.forEach((item, index) => {
                        item.pxSizeX = item.width_px;
                        item.pxSizeY = item.height_px;
                        item.minFileSize = item.file_size_min;
                        item.maxFileSize = item.file_size_max;
                        item.cutSizeX = item.width_mm;
                        item.cutSizeY = item.height_mm;
                        item.title = item.spec_name;
                        item.size = item.width_mm + "X" + item.height_mm;
                        item.values = item.title + "(" + item.size + ")"
                        item.dpi = item.ppi;
                        if(index == 0) {
                            item.selected = true
                        }
                        else {
                            item.selected = false
                        }
                        this.columns.push(item.values)
                        if(index == 0) {
                            if(!localStorage.getItem('imageShown') || localStorage.getItem('imageShown') == '') {
                                // 没有带水印的图片
                                this.selectedList.push(item);
                            }
                        }
                    });
                    this.wxAppId = res.obj.wxAppId;
                    this.wxMchId = res.obj.wxMchId;
                }
            })
        },
        // 确定选择尺寸
        onConfirm(value) {
            if(this.isCreate || this.btnCreating) {
                this.$toast("您已生成证件照，无法更改尺寸")
                this.showPicker = false;
                return;
            }
            this.value = value;
            this.showPicker = false;
            console.log("value ==> ", value)
            this.selectedList = this.sizeList.filter(item => {
                return item.values == value
            })
        },
        handleColorClick(colorObj, index) {
            if(this.isCreate || this.btnCreating) {
                return;
            }
            this.colorList.forEach((item, idx) => {
                if(index == idx) {
                    item.selected = true;
                }
                else {
                    item.selected = false;
                }
            });
            this.pictureBackground = this.colorList.filter((item, idx) => {
                return idx == index
            })
        },
        // 弹出选择尺寸
        selectInch() {
            this.showPicker = true;
        },
        // 去到顶部
        toTop() {
            this.$nextTick(() => {

                // window.scrollTo({
                //     top: 200
                // })
                document.getElementById("diy").scrollIntoView();
                this.$operationLog({
                    btnId: "pc-make photo",
                    type: '0'
                })
            })
        },
        $upload2Image(data) {
            upload2Image(data).then(res => {

            })
        },
        beforeRead(file, detail) {
            let _this = this;
            console.log("before-upload ==> ", file)
            let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'png'
            const extension2 = testmsg === 'jpg'
            const extension3 = testmsg === 'jpeg'
            if(!extension && !extension2 && !extension3) {
                this.$toast({
                    message: '上传文件只能是 png、jpg、jpeg格式!',
                    type: 'error'
                });
                return extension || extension2 || extension3
            }
            this.imgSize = (file.size / 1024).toFixed(1);
            console.log("img size ==> ", this.imgSize)
            if(this.imgSize < 30) {
                this.$toast({
                    message: '上传文件大小不能小于 30KB!',
                    type: 'error'
                });
                return  this.imgSize > 30
            }
            if(this.imgSize / 1024 > 2) {
                this.scale = true
            }
            this.$operationLog({
                btnId: "pc-upload image",
                type: '0'
            })
            if(this.imgSize / 1024 > 4) {
                return new Promise((resolve, reject) => {
                    const fileNames = file.name.split('.');
                    const type = fileNames[fileNames.length - 1];
                    this.handleCompressImage(file, type).then(res => {
                        resolve(res)
                    })
                })
            }
            else {
                return new Promise((resolve, reject) => {
                    const fileNames = file.name.split('.');
                    const type = fileNames[fileNames.length - 1];
                    this.handleImageSize(file, type).then(res => {
                        resolve(res)
                    })
                })
            }
                // return file
        },
        async afterRead(e, detail) {
            let uploadArr = [];
            this.showUploadPicture = true;
            if (e.length) {
                e.forEach((item) => {
                    uploadArr.push(this.uploadFile(item.file));
                });
            } else {
                uploadArr.push(this.uploadFile(e.file));
            }
            Promise.all(uploadArr).then((res) => {
                // console.log('afterRead ==> ', res)
                const { fileList = [] } = this;
                fileList.push(...res);
                this.$toast().clear();
                this.fileList = fileList;
            }).catch(error => {
                this.showUploadPicture = false;
                this.fileList = [];
            });
        },
        uploadFile(file) {
            let params = new FormData();
            params.append("file", file);
            let time = new Date().getTime()
            params.append("_time", time);
            params.append("_sign", JSMD5(`lx0e@_model=src&_time=${time}n0*32`));
            this.imgUpload = true;
            console.log("params ==> ", params)
            return new Promise((resolve) => {
                axios
                    .post("http://api.cameramagic.shunmakeji.com/v1/uploadFile?_model=src", params, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        if (data.success == 1) {
                            this.uploadImage = data.obj;
                            this.showUploadPicture = true;
                            this.$refs["previewImg"].onload = () => {
                                this.imgUpload = false;
                            }
                            resolve(data.obj);
                        } else {
                            this.$toast("上传失败，请重新上传");
                            this.showUploadPicture = false;
                            this.fileList = [];
                        }
                    }).catch(err => {
                        this.showUploadPicture = false;
                        this.fileList = [];
                    });
            });
        },
        /* 图片压缩方法-canvas压缩 */
        handleCompressImage(img, type){
            let vm = this;
            let resultBlob = "";
            // 读取文件
            let reader = new FileReader()
            reader.readAsDataURL(img);
            return new Promise((resolve, reject) => {
                reader.onload = function(e) {
                    let image = new Image(); //新建一个img标签
                    image.src = e.target.result;
                    image.onload = function() {
                        let canvas = document.createElement('canvas');
                        let context = canvas.getContext('2d');
                        // 定义 canvas 大小，也就是压缩后下载的图片大小
                        while(image.width > 2000 || image.height > 2000) {
                            let percent = 0.5;
                            if(image.width / 2000 > 1) {
                                percent = 2000 / image.width;
                                image.width = image.width * percent;
                                image.height = image.height * percent;
                            }
                            else if(image.height / 2000 > 1) {
                                percent = 2000 / image.height;
                                image.width = image.width * percent;
                                image.height = image.height * percent;
                            }
                        }
                        let imageWidth = image.width; //压缩后图片的大小
                        let imageHeight = image.height;
                        canvas.width = imageWidth;
                        canvas.height = imageHeight;
                        // 图片不压缩，全部加载展示
                        context.drawImage(image, 0, 0, imageWidth, imageHeight);
                        console.log(imageWidth, "height ==> ", imageHeight)
                        // 图片按压缩尺寸载入
                        // let imageWidth = 500; //压缩后图片的大小
                        // let imageHeight = 200;
                        // context.drawImage(image, 0, 0, 500, 200);
                        // 图片去截取指定位置载入
                        // context.drawImage(image,100, 100, 100, 100, 0, 0, imageWidth, imageHeight);
                        if(type == 'jpg') {
                            type = 'jpeg'
                        }
                        vm.imgBase64 = canvas.toDataURL(`image/${type}`, 0.5);
                        resultBlob = vm.dataURItoBlob(vm.imgBase64);
                        if(type == 'jpeg' || type == 'jpg') {
                            resolve(new File([resultBlob], new Date().getTime() + '.jpg'))
                        }
                        else if(type == 'png') {
                            resolve(new File([resultBlob], new Date().getTime() + '.png'))
                        }
                    };
                }
            })
        },
        handleImageSize(img, type) {
            let vm = this;
            let resultBlob = "";
            // 读取文件
            let reader = new FileReader()
            reader.readAsDataURL(img);
            return new Promise((resolve, reject) => {
                reader.onload = function(e) {
                    let image = new Image(); //新建一个img标签
                    image.src = e.target.result;
                    image.onload = function() {
                        let canvas = document.createElement('canvas');
                        let context = canvas.getContext('2d');
                        // 定义 canvas 大小，也就是压缩后下载的图片大小
                        while(image.width > 2000 || image.height > 2000) {
                            let percent = 0.5;
                            if(image.width / 2000 > 1) {
                                percent = 2000 / image.width;
                                image.width = image.width * percent;
                                image.height = image.height * percent;
                            }
                            else if(image.height / 2000 > 1) {
                                percent = 2000 / image.height;
                                image.width = image.width * percent;
                                image.height = image.height * percent;
                            }
                        }
                        let imageWidth = image.width; //压缩后图片的大小
                        let imageHeight = image.height;
                        canvas.width = imageWidth;
                        canvas.height = imageHeight;
                        // 图片不压缩，全部加载展示
                        context.drawImage(image, 0, 0, imageWidth, imageHeight);
                        console.log(imageWidth, "height ==> ", imageHeight)
                        // 图片按压缩尺寸载入
                        // let imageWidth = 500; //压缩后图片的大小
                        // let imageHeight = 200;
                        // context.drawImage(image, 0, 0, 500, 200);
                        // 图片去截取指定位置载入
                        // context.drawImage(image,100, 100, 100, 100, 0, 0, imageWidth, imageHeight);
                        if(type == 'jpg') {
                            type = 'jpeg'
                        }
                        vm.imgBase64 = canvas.toDataURL(`image/${type}`, 1);
                        resultBlob = vm.dataURItoBlob(vm.imgBase64);
                        if(type == 'jpeg' || type == 'jpg') {
                            resolve(new File([resultBlob], new Date().getTime() + '.jpg'))
                        }
                        else if(type == 'png') {
                            resolve(new File([resultBlob], new Date().getTime() + '.png'))
                        }
                    };
                }
            })
        },
        /* base64转Blob对象 */
        dataURItoBlob(data) {
            let byteString;
            if(data.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(data.split(',')[1])
            }else {
                byteString = unescape(data.split(',')[1])
            }
            let mimeString = data
                .split(',')[0]
                .split(':')[1]
                .split(';')[0];
            let ia = new Uint8Array(byteString.length)
            for( let i = 0; i < byteString.length; i += 1) {
                ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], {type: mimeString})
        },
        // 图片预览
        previewImage(url) {
            ImagePreview([url]);
        },
        reUpload() {
            this.fileList = [];
            this.showUploadPicture = false;
            this.uploadImage = '';
            this.btnCreating = false;
            this.isCreate = false;
            this.paySuccess = false;
            this.selectedList[0] = this.sizeList[0];
            // 删除制作好的图片 清除状态
            localStorage.setItem('imageShown', '');
            localStorage.setItem('colorSelect', '');
            localStorage.setItem('pictureBackground', '');
            localStorage.setItem('isBeauty', '');
            localStorage.setItem('selectedList', '');
            localStorage.setItem('selectedSizeX', '');
            localStorage.setItem('selectedSizeY', '');
            localStorage.removeItem('imageShown');
            localStorage.removeItem('colorSelect');
            localStorage.removeItem("pictureBackground");
            localStorage.removeItem("isBeauty");
            localStorage.removeItem('selectedList');
            localStorage.removeItem('selectedSizeX');
            localStorage.removeItem('selectedSizeY');
            localStorage.removeItem("uploadImage")
            // this.isDownLoad = false;
            localStorage.removeItem('payObject');
        },
        // 生成不换装证件照
        $createCrditUnChange() {
            if(this.imgSize < 30) {
                this.$toast("上传图片过小，不能低于30KB");
                return;
            }
            this.$operationLog({
                btnId: "pc-make finish",
                type: "0"
            })
            let pxSizeX = 0;
            let pxSizeY = 0;
            let sharp = 0;
            let smooth = 0;
            let white = 0;
            if(this.selectedList.length > 0) {
                pxSizeX = this.selectedList[0].pxSizeX;
                pxSizeY = this.selectedList[0].pxSizeY;
            }
            console.log("大小:", this.imgSize, "sizeList", this.selectedList)

            if(this.isBeauty) {
                sharp = 0.6;
                smooth = 0.6;
                white = 0.6;
            }
            this.btnCreating = true;
            createCrditUnChange({
                cutSizeX: pxSizeX,
                cutSizeY: pxSizeY,
                sharp,
                smooth,
                white,
                imgInPath: this.uploadImage,
                apkChannel,
                bd_vid: this.bd_vid
            }).then(res => {
                if(res.success == "1") {
                    this.btnCreating = false;
                    this.imageShown = res.obj;
                    // 保存制作好的图片
                    localStorage.setItem("imageShown", this.imageShown);
                    localStorage.setItem("uploadImage", this.uploadImage);
                    localStorage.setItem('isBeauty', this.isBeauty ? 1 : 0);
                    this.isCreate = true;
                    this.$operationLog({
                        btnId: "pc-preview photo",
                        type: "0"
                    })
                    this.createImage()
                    // this.calcImage()
                }
                else {
                    if(typeof res.msg && res.msg.length > 0) {
                        this.$toast(res.msg)
                    } else {
                        this.$toast("制作失败，请重新上传图片")
                    }
                    console.log("file")
                    this.btnCreating = false;
                    this.fileList = [];
                    // this.$refs.upload.clearFiles()
                }
            }).catch(err => {
                console.log("error ==> ", err);
                this.$toast("制作失败，请重新上传图片")
                this.showUploadPicture = false;
                this.btnCreating = false;
                this.fileList = [];
            })
        },
        // 生成预览照片
        createImage() {
            let pxSizeX = 0;
            let pxSizeY = 0;
            pxSizeX = this.selectedList[0].pxSizeX
            pxSizeY = this.selectedList[0].pxSizeY
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })

            // 保存选择的颜色 尺寸等信息
            localStorage.setItem('colorSelect', color)
            localStorage.setItem("selectedList", JSON.stringify(this.selectedList[0]));
            localStorage.setItem("pictureBackground", JSON.stringify(this.pictureBackground[0]))
            localStorage.setItem('selectedSizeX', pxSizeX)
            localStorage.setItem('selectedSizeY', pxSizeY)
            // end
            let canvas = null;
            if(document.getElementById("picture-preview")) {
                canvas = document.getElementById("picture-preview")
            }
            else {
                canvas = document.createElement('canvas')
            }
            canvas.setAttribute("id", "picture-preview")
            if(pxSizeX > 200 && pxSizeX <= 400) {
                pxSizeX = pxSizeX * 0.4;
                pxSizeY = pxSizeY * 0.4;
            }
            if(pxSizeX > 400 && pxSizeX <= 600) {
                pxSizeX = pxSizeX * 0.28
                pxSizeY = pxSizeY * 0.28
            }
            if(pxSizeX > 600 && pxSizeX <= 800) {
                pxSizeX = pxSizeX * 0.18
                pxSizeY = pxSizeY * 0.18
            }
            if(pxSizeX > 800 && pxSizeX <= 1000) {
                pxSizeX = pxSizeX * 0.15
                pxSizeY = pxSizeY * 0.15
            }
            if(pxSizeX > 1000) {
                pxSizeX = pxSizeX * 0.1
                pxSizeY = pxSizeY * 0.1
            }
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            canvas.style = "background: url(" + require("@A/images/color-trans.png") + ");max-width: 110px;max-height: 153px"
            let ctx = canvas.getContext('2d')

            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.imageShown;
            // image.src = "http://oss.sh.shunmakeji.cn/upload/identification/waterm"

            image.width = pxSizeX;
            image.height = pxSizeY;
            console.log("image ==> ", image)
            image.onload = function() {
                document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                // let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                // let dataURL300DPI = changeDpiDataUrl(dataURL, 300);
                // console.log("dataURL ==> ", dataURL)
            }
        },
        // 生成状态保存的照片
        createSaveImage() {
            if(!localStorage.getItem('colorSelect') || !localStorage.getItem('selectedSizeX') || !localStorage.getItem('selectedSizeY')) {
                this.$toast("状态保存失败，请重新上传图片")
                return
            }
            let pxSizeX = 0;
            let pxSizeY = 0;
            let color = '';
            this.colorList.forEach((item, index) => {
                if(item.color == localStorage.getItem('colorSelect')) {
                    item.selected = true;
                }
                else {
                    item.selected = false
                }
            })
            color = localStorage.getItem('colorSelect')
            pxSizeX = localStorage.getItem('selectedSizeX')
            pxSizeY = localStorage.getItem('selectedSizeY')
            let canvas = null;
            if(document.getElementById("picture-preview")) {
                canvas = document.getElementById("picture-preview")
            }
            else {
                canvas = document.createElement('canvas')
            }
            canvas.setAttribute("id", "picture-preview")
            if(pxSizeX > 200 && pxSizeX <= 400) {
                pxSizeX = pxSizeX * 0.4;
                pxSizeY = pxSizeY * 0.4;
            }
            if(pxSizeX > 400 && pxSizeX <= 600) {
                pxSizeX = pxSizeX * 0.28
                pxSizeY = pxSizeY * 0.28
            }
            if(pxSizeX > 600 && pxSizeX <= 800) {
                pxSizeX = pxSizeX * 0.18
                pxSizeY = pxSizeY * 0.18
            }
            if(pxSizeX > 800 && pxSizeX <= 1000) {
                pxSizeX = pxSizeX * 0.15
                pxSizeY = pxSizeY * 0.15
            }
            if(pxSizeX > 1000) {
                pxSizeX = pxSizeX * 0.1
                pxSizeY = pxSizeY * 0.1
            }
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            canvas.style = "background: url(" + require("@A/images/color-trans.png") + ");max-width: 110px;max-height: 153px"
            let ctx = canvas.getContext('2d')

            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            let image = new Image();
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.imageShown;
            // image.src = "http://oss.sh.shunmakeji.cn/upload/identification/waterm"

            image.width = pxSizeX;
            image.height = pxSizeY;
            console.log("image ==> ", image)
            image.onload = function() {
                document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                // let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                // let dataURL300DPI = changeDpiDataUrl(dataURL, 300);
                // console.log("dataURL ==> ", dataURL)
            }
        },
        // 计算目标照片大小
        calcImage() {
            let that = this;
            this.isCalc = true;
            let pxSizeX = 0;
            let pxSizeY = 0;
            pxSizeX = this.selectedList[0].pxSizeX
            pxSizeY = this.selectedList[0].pxSizeY
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            let image = new Image();
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.imageShown;
            image.width = pxSizeX;
            image.height = pxSizeY;
            image.onload = function() {
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 2.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, 300);
                that.targetSize = ((dataURL300DPI.split(",")[1].length - (dataURL300DPI.split(",")[1].length / 8) * 2) / 1024).toFixed(1)
                that.isCalc = false;
                // console.log("dataURL ==> ", dataURL)
            }
        },

        // 支付19.99元，获取无水印版本证件照
        downloadClick() {
            this.showPayDialog = true;
        },
        // 获取价格配置
        $getPriceSetup() {
             getPriceSetup({
                apkId,
                apkChannel,
                apkVersionCode,
                phoneOs,
                phoneModel,
                apkVersionName,
                phoneId: this.phoneId,
                phoneOsVersion,
            }).then(res => {
                this.price = res.obj[0].price
            })
        },

        // 微信支付/支付宝按钮点击
        changePayWay(payway) {
            this.payWay = payway;
            console.log("pay selectedList ==> ", this.selectedList);
            let extendJson = {
                bgColorName: this.pictureBackground[0].title,
                bgcolor: this.pictureBackground[0].color,
                clothId: "no_cloth",
                cutPicCode: "",
                goodsName: this.selectedList[0].title,
                goodsOutlineText: "规格：" + this.selectedList[0].pxSizeX + "X" + this.selectedList[0].pxSizeY + " " + this.selectedList[0].size,
                img_wm_url: "",
                mKeys: ["商品名称", "规格尺寸"],
                // mOssPath: this.mOssPath,
                // mOssAbsolutePath: this.uploadImage,
                // mOssAbsolutePath: this.koutuImage,
                mOssAbsolutePath: this.uploadImage,
                mSpecId: this.selectedList[0].spec_id,
                mSpecName: this.selectedList[0].title,
                pxSizeX: this.selectedList[0].pxSizeX,
                pxSizeY: this.selectedList[0].pxSizeY,
                dpi: this.selectedList[0].dpi
            }
            let callbackAddress = location.origin + "/result?channel=" + localStorage.getItem("apkChannel") || apkChannel + "&apkChannel=" + localStorage.getItem("apkChannel") || apkChannel
            this.$operationLog({
                btnId: "pc-submit orders",
                type: "0"
            })
            // 获取价格
            getPriceSetup({
                apkId,
                apkChannel,
                apkVersionCode,
                phoneOs,
                phoneModel,
                apkVersionName,
                phoneId: this.phoneId,
                phoneOsVersion,
            }).then(ret => {
                if(ret.success == "1") {
                    this.price = ret.obj[0].price
                    this.payStart = true;
                    let payObject = {
                        apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                        apkId,
                        type: 0,
                        apkVersionCode,
                        apkVersionName,
                        money: this.price,
                        payWay: this.payWay,
                        phoneId: this.phoneId,
                        phoneOs: phoneOs,
                        phoneModel,
                        phoneOsVersion,
                        wxAppId: this.wxAppId,
                        wxMchId: this.wxMchId,
                        extendJson: JSON.stringify(extendJson),
                        bdCallbackUrl: this.bd_vid,
                        aliReturnUrl: location.href.indexOf("localhost") != -1 ? "http://testzhengjianzhao.huifurj.com/result" : callbackAddress
                    }
                    // 保存支付状态
                    localStorage.setItem("payObject", JSON.stringify(payObject));
                    if(this.payWay == 4) {
                        this.timeoutTimer = setTimeout(() => {
                            // console.log('## setTImeout ##')
                            // if(this.$route.name == 'result') {
                            //     return;
                            // }
                            localStorage.setItem("showPayModal", 1)
                            this.$store.dispatch("showPayModal");
                        }, 1000)
                    }
                    pay(payObject).then(res => {
                        if(res.success == "1") {
                            this.orderId = res.obj.id;
                            localStorage.setItem("orderId", this.orderId)
                            if(this.payWay == 4) {
                                // this.payQRCode = res.sign.codeUrl;
                                // window.open(res.sign.html);

                                window.location.href = res.sign.html;
                                // this.timer = setInterval(() => {
                                //     this.$searchOrder(this.orderId)
                                // }, 1000)
                            }
                            else if(this.payWay == 5) {
                                // 支付宝支付
                                let path = res.html;
                                localStorage.setItem("showPayModal", 0);
                                // window.open(path);
                                this.$store.dispatch("hidePayModal");
                                window.location.href = path;
                                // this.payQRCode = path;
                                // console.log("QRCode", this.payQRCode)
                            }

                        }
                    })
                }
            })
        },

        // 订单查询
        $searchOrder(id) {
            // if(this.isDownLoad) {
            //     return
            // }
            searchOrder({
                id: id
            }).then(res => {
                if(res.success == "1") {
                    if(res.obj.status == 1) {
                        this.paySuccess = true;
                        this.resultPicture = res.obj.uidFolder;
                        // this.downloadPicture()
                        // this.isDownLoad = true;
                        this.payPictureSuccess()
                        this.$operationLog({
                            btnId: "pc-payment suc",
                            type: "0"
                        })
                        if(this.timer) {
                            clearInterval(this.timer)
                        }
                        if(this.wechatTimer) {
                            clearInterval(this.wechatTimer)
                        }
                    }
                }
            })
        },
        payPictureSuccess() {
            // this.$store.dispatch("hidePayModal");
            // localStorage.setItem("showPayModal", 0);
            this.$toast("支付成功")
            if(this.timer) {
                clearInterval(this.timer)
            }
            if(this.wechatTimer) {
                clearInterval(this.wechatTimer)
            }
            this.$router.push({
                name: 'result',
                query: {
                    orderId: this.orderId || localStorage.getItem("orderId"),
                    channel: localStorage.getItem("apkChannel") || apkChannel,
                    apkChannel: localStorage.getItem("apkChannel") || apkChannel,
                    bd_vid: this.bd_vid
                }
            })
        },
        // 下载去水印图片
        downloadResultPicture() {
            this.$operationLog({
                btnId: "pc-download photo",
                type: "0"
            });
            this.downloadPicture()
        },
        downloadPicture() {
            let that = this;
            let pxSizeX = 0;
            let pxSizeY = 0;
            let title = "";
            let dpi = 300
            pxSizeX = this.selectedList[0].pxSizeX;
            pxSizeY = this.selectedList[0].pxSizeY;
            title = this.selectedList[0].title;
            dpi = this.selectedList[0].dpi;
            let color = ""
            this.colorList.forEach(item => {
                if(item.selected) {
                    if(item.type == 'img') {
                        color = "#00000000"
                    }
                    else {
                        color = item.color
                    }
                }
            })
            let picture_link = document.createElement('a');
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.setAttribute("id", "result-picture")
            canvas.width = pxSizeX;
            canvas.height = pxSizeY;
            let image = new Image();
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, pxSizeX, pxSizeY)
            image.setAttribute('crossOrigin', "Anonymous")
            image.src = this.resultPicture;
            image.width = pxSizeX;
            image.height = pxSizeY;
            image.onload = function() {
                // document.querySelector("#picture-container").appendChild(canvas)
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let dataURL = canvas.toDataURL('image/jpeg', 1.0);
                let dataURL300DPI = changeDpiDataUrl(dataURL, dpi)
                that.base64DPI300 = dataURL300DPI;
                that.showResult = 1;
                that.$store.dispatch('hidePayModal');
                localStorage.setItem("showPayModal", 0);
                that.showPayDialog = false;
                // var blobBin = atob(dataURL300DPI.split(',')[1]);
                // var array = [];
                // for(var i = 0; i < blobBin.length; i++) {
                //     array.push(blobBin.charCodeAt(i));
                // }

                // var img = new Blob([new Uint8Array(array)], {type: "image/jpeg"});

                // picture_link.href = URL.createObjectURL(img);
                // picture_link.target = "_blank";
                // picture_link.download = that.title + ".jpg";
                // picture_link.click()
                console.log("download click")
                that.$operationLog({
                    btnId: "pc-download auto",
                    type: "0"
                })
            }
        },
        onPayDialogClose() {
            if(this.timer) {
                clearInterval(this.timer)
            }
        }
    },

}
</script>

<style lang="scss" scoped>
    .home-container{
        background-color: #EFF0F4;
        // banner
        .img-banner{
            width: 100%;
            margin-top: 102px;
        }
        // 制作图片
        .make-photo{
            background-color: white;
            padding-bottom: 48px;
            margin: 32px 64px 0;
            box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
            .make-photo-top {
                padding: 32px 32px 24px;
                .make-photo-click-container{
                    height: 317px;
                    background-image: url(../assets/images/mobile/mobile_make_bg.png);
                    background-size: 100% 100%;
                    background-color: #F5F5F5;
                    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
                    .make-photo-click{
                        width: 64px;
                        height: 64px;

                    }
                }
                .txt-click-upload{
                    width: 558px;
                    height: 70px;
                    background: $--color-primary;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 70px;
                }
                .photo-preview-container {
                    width: 100%;
                    .photo-preview-area {
                        width: 100%;
                        background-image: url(../assets/images/mobile/mobile_make_bg.png);
                        background-size: 100% 100%;
                        background-color: #F5F5F5;
                        height: 317px;
                        .preview-image {
                            max-width: 279px;
                            max-height: 317px;
                        }
                        // .van-image-preview__image/deep/ {
                        //     width: 100%;
                        //     height: 100%;
                        // }
                    }
                    .re-upload-btn {
                        background-color: $--color-primary;
                        height: 70px;
                    }
                }
                .photo-size-container{
                    width: 558px;
                    margin-top: 33px;
                    .select-inch-container{
                        padding: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #484848;
                        background: #F6F8F9;
                        border: 1px solid #CCCCCC;
                        margin-left:32px;
                        .img-select{
                            width: 24px;
                            height: 24px;
                            margin-left: 60px;
                        }
                    }

                }

                // 生成证件照预览样式
                .create-box {
                    background-color: #F5F5F5;
                    .create-preview-area {
                        background-image: url(../assets/images/mobile/mobile_make_bg.png);
                        background-size: 100% 100%;
                        width: 100%;
                        height: 317px;
                        .preview-left {
                            .preview-image {
                                max-width: 220px;
                                max-height: 306px;
                            }
                        }
                        .preview-right {
                            position: relative;
                            .top-container {
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                width: 100%;
                                height: 100%;
                                .top {
                                    width: 100%;
                                    height: 130px;
                                    border-bottom: 1px solid #e5e5e5;
                                }
                                .bottom {
                                    width: 100%;
                                    height: 130px;
                                    border-top: 1px solid #e5e5e5;
                                }
                            }
                            .left {
                                border-right: 1px solid #e5e5e5;
                                .left-top {
                                    width: 100px;
                                    height: 130px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: flex-end;
                                    border-right: 1px solid #e5e5e5;
                                    border-bottom: 1px solid #e5e5e5;
                                    img {
                                        width: 80px;
                                        height: 110px;
                                    }
                                }
                                .left-bottom {
                                    width: 100px;
                                    height: 130px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: flex-start;
                                    border-top: 1px solid #e5e5e5;
                                    border-right: 1px solid #e5e5e5;
                                    img {
                                        width: 80px;
                                        height: 110px;
                                    }
                                }
                                .border-bottom-line {
                                    width: 100;
                                }
                            }
                            .right {
                                border-left: 1px solid #e5e5e5;
                                .right-top {
                                    width: 100px;
                                    height: 130px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: flex-end;
                                    border-left: 1px solid #e5e5e5;
                                    border-bottom: 1px solid #e5e5e5;
                                    img {
                                        width: 80px;
                                        height: 110px;
                                    }
                                }
                                .right-bottom {
                                    width: 100px;
                                    height: 130px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    border-left: 1px solid #e5e5e5;
                                    border-top: 1px solid #e5e5e5;
                                    img {
                                        width: 80px;
                                        height: 110px;
                                    }
                                }
                            }
                        }
                    }
                    .re-upload-btn {
                        height: 70px;
                    }
                }
                .photo-bg-container{
                    width: 558px;
                    .color-container {
                        margin-top: 16px;
                        gap: 33px;
                        .color-box-item {
                            width: 56px;
                            height: 56px;
                            border: 1px solid #00000000;
                            padding: 4px;
                        }
                        .white-border {
                            border: 1px solid #E5E5E5;
                        }
                        .active {
                            border: 1px solid $--color-primary;
                            position: relative;
                            &::after {
                                content: '';
                                background-image: url(../assets/images/mobile/mobile_true.png);
                                background-size: 100%;
                                position: absolute;
                                width: 56px;
                                height: 56px;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);

                            }
                        }
                    }
                }

                .right-title{
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #111111;
                    line-height: 33px;
                    width: 140px;
                    text-align: left;
                }

                .create-btn{
                    width: 100%;
                    height: 90px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    line-height: 32px;
                    color: white;
                    background: $--color-primary;
                    margin: 26px 0 0;
                }
                .download-btn {
                    width: 100%;
                    height: 90px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: white;
                    background-color: #21C87A;
                    margin: 26px 0 0;
                }
            }
        }


        // 底部
        .bottom-container{
            background: white;
            margin-top: 48px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 100px;
            padding-bottom: 41px;
            .bottom-top-title{
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
                color: #111111;
                line-height: 45px;
                letter-spacing: 1px;
            }
            .girl-container{
                margin-top: 48px;
                .girl_photo{
                    width: 138px;
                    height: 234px;
                }
                .girl_photo_left{
                    margin-left: 32px;
                }
            }
            .progress-container{
                margin-top: 23px;
                .txt-progress{
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #484848;
                }
                .img-progress{
                    width: 50px;
                    height: 32px;
                    margin-left: 14px;
                    margin-right: 14px;

                }
            }
            .girl-click{
                width: 558px;
                height: 90px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 32px;
                background: $--color-primary;
                margin-top: 48px;
            }

            .bottom-top-type-title{
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: bold;
                color: #111111;
                line-height: 45px;
                letter-spacing: 1px;
                margin-top: 100px;
            }
        }

        .type-container{
            .txt-type{
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #484848;
                line-height: 33px;
                margin-top: 24px;
            }
            .img-type{
                width: 204px;
                height: 271px;
            }
            .type-left{
                margin-left: 35px;
            }
            .type-one{
                margin-top: 47px;
            }
            .type-two{
                margin-top: 33px;
            }
        }

        .co-info{
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #808080;
            line-height: 36px;
            margin-top: 23px;
            margin-left: 80px;
            margin-right: 80px;

        }

        .popWrap/deep/ {
            .van-picker-column__item--selected {
                color: $--color-primary;
            }
        }

        .loading-popup {
            width: 240px;
            height: 240px;
        }

        .pay-dialog-container {
            .pay-dialog {
                padding: 40px;
                .price {
                    color: #E65351;
                    font-size: 42px;
                    font-weight: 600;
                }
                .tips {
                    margin-top: 16px;
                    width: 367px;
                }
                .btn-container {
                    margin-top: 48px;
                    .wechat-btn {
                        border: 1px solid #E5E5E5;
                        height: 60px;
                        img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                    .alipay-btn {
                        border: 1px solid #E5E5E5;
                        height: 60px;
                        margin-top: 24px;
                        img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }

            .pay-success {
                padding: 64px 30px;
                .pay-status {
                    width: 100px;
                    height: 100px;
                }
                .success-tips {
                    margin-top: 24px;
                    color: #111111;
                    font-weight: 500;
                }
                .down-load-btn {
                    margin-top: 48px;
                    border: 1px solid $--color-primary;
                    color: $--color-primary;
                    width: 288px;
                    height: 60px;
                    font-size: 25px;
                }
                .tips {
                    width: 384px;
                    margin-top: 24px;
                }
            }
        }
    }
    .picture-container {
        height: calc(100vh - 101px);
        min-height: calc(100vh - 101px);
        background-color: white;
        margin-top: 100px;
        .main {
            height: calc(100% - 201px);
            background-color: #eff3f4;
            padding: 54px 32px;
            box-sizing: border-box;
            .exported-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-style: none;
            }
        }
        .control {
            background-color: white;
            padding: 48px;
            .long-btn {
                background-color: $--color-primary;
                color: white;
                border-radius: 120px;
                height: 100px;
                width: 100%;
            }
            .hidden-image {
                position: absolute;
                height: 100vh;
                width: 100vw;
                object-fit: cover;
                opacity: 0;
                left: 0;
                top: 0;
            }
        }
    }
</style>
